var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"moder-publications-calendar"},[_c('div',{staticClass:"moder-publications-calendar__content"},[_c('div',{staticClass:"moder-publications-calendar__top moder-publications-calendar__row"},_vm._l((_vm.week),function(day){return _c('div',{key:day,staticClass:"moder-publications-calendar__column"},[_c('p',[_vm._v(_vm._s(day))])])}),0),_c('div',{staticClass:"moder-publications-calendar__body moder-publications-calendar__row"},_vm._l((_vm.week),function(item,index){return _c('div',{key:item,staticClass:"moder-publications-calendar__column moder-publications-calendar__item main-color"},[_vm._m(0,true),_c('ul',{staticClass:"moder-publications-calendar__list"},_vm._l((_vm.filterPublications(index)),function(article){return _c('li',{key:article.id,staticClass:"moder-publications-calendar__li",style:({
              position: _vm.currentArticle
                ? _vm.currentArticle.id == article.id
                  ? 'relative'
                  : 'static'
                : 'static',
              color: _vm.currentArticle
                ? _vm.currentArticle.id == article.id
                  ? '#b90c0c'
                  : ''
                : '',
            }),on:{"click":function($event){return _vm.changeArticle(article)}}},[_c('p',{staticClass:"moder-publications-calendar__article"},[_vm._v(" "+_vm._s(article.title)+" ")]),_c('p',{staticClass:"moder-publications-calendar__author"},[_vm._v(" Александр Гопоненко ")]),(_vm.currentArticle && article.id == _vm.currentArticle.id)?_c('Modal',{attrs:{"currentArticle":_vm.currentArticle,"classOfParent":'moder-publications-calendar__article'},on:{"closeModal":function($event){_vm.currentArticle = null}}}):_vm._e()],1)}),0)])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"moder-publications-calendar__info"},[_c('p',{staticClass:"moder-publications-calendar__subtitle"},[_vm._v("26")]),_c('p',{staticClass:"moder-publications-calendar__subtitle_light"},[_vm._v(" 10 публикаций ")])])
}]

export { render, staticRenderFns }